<template>
  <!-- <LogoutButton v-if="$auth.isAuthenticated" /> -->
  <Select v-if="step === 1" :loc="loc" @submit="onSelectionSubmit" />
  <Submit v-else-if="step === 2" :account-id="meter._id" @submit="onReadingSubmit" @back="onBack" />
  <Photo v-else-if="step === 3" @submit="onPhotoSubmit" @back="onBack" />
  <Confirmation v-else-if="step === 4" :meter="meter" :reading="reading" :s3-key="s3Key" :photo-uri="photoUri" @back="onBack" @reset="onReset" />
</template>

<script>
import { Geolocation } from '@capacitor/geolocation';

import Confirmation from './Confirmation';
import Photo from './Photo';
import Select from './Select';
import Submit from './Submit';

export default {
  name: 'Readings',
  components: {
    Confirmation,
    Photo,
    Select,
    Submit
  },
  data() {
    return {
      loc: {
        lat: null,
        long: null
      },
      step: 1,
      meter: null,
      reading: null,
      s3Key: null,
      photoUri: null
    };
  },
  async mounted() {
    await this.getPosition();
  },
  methods: {
    async getPosition() {
      const pos = await Geolocation.getCurrentPosition();

      this.loc.lat = pos.coords.latitude;
      this.loc.long = pos.coords.longitude;
    },
    onSelectionSubmit(value) {
      this.meter = value;
      this.step = 2;
    },
    onReadingSubmit(value) {
      this.reading = value;
      this.step = 3;
    },
    onPhotoSubmit(s3Key, photoUri) {
      this.s3Key = s3Key;
      this.photoUri = photoUri;
      this.step = 4;
    },
    onBack() {
      if (this.step === 1) return;

      this.step -= 1;
    },
    onReset() {
      this.step = 1;
      this.meter = null;
      this.reading = null;
      this.s3Key = null;
      this.photoUri = null;
    }
  }
};
</script>
