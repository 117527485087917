<template>
  <div>
    <div class="content">
      <div class="card mb-3">
        <div class="mb-2">
          <h3 class="mb-1">Enter Meter Reading</h3>
          <input v-model="newReading" class="form-control form-control-lg" type="number" step="1" min="0" />
        </div>
        <div>
          <p class="mb-1">Has this meter been reset since the last read?</p>
          <input id="isReset" v-model="isReset" type="checkbox" />
          <label for="isReset"> Yes</label>
        </div>
      </div>
      <div class="card">
        <h3 class="mb-1">Recent meter readings</h3>
        <div>
          <table v-if="!readingsLoading">
            <thead>
              <tr>
                <th>Submitted At</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="reading in readings" :key="reading._id">
                <td>{{ reading.submittedAt }}</td>
                <td>{{ reading.value }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="spinner-wrapper">
            <Spinner />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-box">
      <div class="row">
        <div class="col-3">
          <button class="btn btn-block btn-grey" @click="onClickBack">Back</button>
        </div>
        <div class="col-9">
          <button class="btn btn-block" @click="onClickNext">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Spinner from '@/components/Spinner';

export default {
  name: 'Submit',
  components: {
    Spinner
  },
  props: {
    accountId: {
      type: String,
      required: true
    },
    subMeterId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      readings: [],
      readingsLoading: true,
      newReading: '',
      showPrevious: false,
      photoUrl: null,
      isReset: false
    };
  },
  mounted() {
    this.getReadings();
  },
  methods: {
    async getReadings() {
      this.readingsLoading = true;
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/readings?accountId=${this.accountId}&$sort=submittedAt:-1&$limit=6`);

      this.readings = body.data.map(reading => {
        const [year, month, day] = reading.submittedAt.slice(0, 10).split('-');
        reading.submittedAt = `${day}/${month}/${year}`;
        return reading;
      });

      this.readingsLoading = false;
    },
    onClickNext() {
      this.$emit('submit', { reading: this.newReading, isReset: this.isReset });
    },
    onClickBack() {
      this.$emit('back');
    }
  }
};
</script>
