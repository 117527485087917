import Vue from 'vue';

const registerInterceptors = (token, authService) => {
  Vue.http.interceptors.push((request, next) => {
    try {
      if (!request.url.includes('.amazonaws.com/')) request.headers.set('Authorization', `Bearer ${token}`);

      next(res => {
        if (res.status === 401) authService.logout();
      });
    } catch (e) {
      console.log(e);
    }
  });
};

export default registerInterceptors;
