<template>
  <div>
    <div class="content">
      <div class="card mb-3">
        <div class="mb-3">
          <p class="mb-1">Select the Etainabl <strong>Site</strong> you are submitting a meter reading for.</p>

          <AssetSelector :assets="sortedAssets" :loading="assetsLoading" @select="onSelectAsset"></AssetSelector>
        </div>

        <div v-if="selectedAssetId">
          <p class="mb-1">Select the Etainabl <strong>Meter</strong> you are submitting a meter reading for.</p>

          <MeterSelector :meters="sortedMeters" :loading="accountsLoading" @select="onSelectMeter"></MeterSelector>
        </div>
      </div>

      <div v-if="selectedMeter" class="card">
        <div class="mb-2">
          Selected Meter: <strong>{{ selectedMeter.name }}</strong>
        </div>
        <table>
          <tbody>
            <tr>
              <th>MPAN</th>
              <td>{{ selectedMeter.meterPointNumber }}</td>
            </tr>
            <tr>
              <th>Type</th>
              <td class="text-capitalize">{{ selectedMeter.type }}</td>
            </tr>
            <tr>
              <th>Serial No.</th>
              <td>{{ selectedMeter.meterSerialNumber }}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{{ selectedMeter.name }}</td>
            </tr>
            <tr>
              <th>Location</th>
              <td>{{ selectedMeter.location }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="selectedAccountId && selectedAssetId" class="footer-box">
      <button class="btn btn-block" @click="onClickNext">Next</button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';

import MeterSelector from '@/components/MeterSelector';
import AssetSelector from '@/components/AssetSelector';

export default {
  name: 'Select',
  components: {
    MeterSelector,
    AssetSelector
  },
  props: {
    loc: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      accounts: [],
      assets: [],
      selectedAssetId: null,
      selectedAccountId: null,
      accountsLoading: false,
      assetsLoading: true
    };
  },
  computed: {
    selectedMeter() {
      return this.sortedMeters.find(meter => meter._id === this.selectedAccountId);
    },
    sortedAssets: function () {
      const assets = [...this.assets];

      if (this.loc && this.loc.lat && this.loc.long) {
        const assetsWithDistance = assets.map(a => {
          const distance = this.getDistance(this.loc.lat, this.loc.long, a.address.latitude, a.address.longitude);

          return {
            ...a,
            distance
          };
        });

        assetsWithDistance.sort((a, b) => a.distance - b.distance);

        return assetsWithDistance;
      }

      assets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      return assets;
    },
    sortedMeters: function () {
      const meters = [...this.accounts];

      meters.sort((a, b) => a.name.localeCompare(b.name));

      return meters;
    }
  },
  async mounted() {
    await this.getAssets();
  },
  methods: {
    getDistance(lat1, lon1, lat2, lon2) {
      const R = 6371;
      const dLat = ((lat2 - lat1) * Math.PI) / 180;
      const dLon = ((lon2 - lon1) * Math.PI) / 180;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      return d;
    },
    async getAccounts(id) {
      this.accountsLoading = true;
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts?assetId=${id}&type=electricity,gas,water&$multi=true`);

      this.accounts = body.data;
      this.accountsLoading = false;
    },
    async getAssets() {
      this.assetsLoading = true;
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets`);

      this.assets = body.data;
      this.assetsLoading = false;
    },
    onSelectAsset(val) {
      this.selectedAssetId = val;
      this.getAccounts(val);
    },
    onSelectMeter(val) {
      this.selectedAccountId = val;
    },
    onClickNext() {
      this.$emit('submit', this.selectedMeter);
    }
  }
};
</script>
