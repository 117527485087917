import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import axios from 'axios';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({ onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, redirectUri = window.location.origin, ...options }) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        company: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        isDesktop: false
      };
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        redirect_uri: redirectUri,
        ...options
      });

      const handleVueCallback = async url => {
        try {
          if (url.includes('state=') && (url.includes('code=') || url.includes('error'))) {
            const { appState } = await this.auth0Client.handleRedirectCallback(url);

            this.error = null;

            Browser.close();

            onRedirectCallback(appState, this.isDesktop);
          }
        } catch (e) {
          this.error = e;
        } finally {
          // Initialize our internal authentication state
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.user = await this.auth0Client.getUser();

          if (this.isAuthenticated) {
            const token = await this.auth0Client.getTokenSilently();

            const response = await axios.get(`${process.env.VUE_APP_API_URL}/users/me/company`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            this.company = response.data;
          }

          this.loading = false;
        }
      };

      console.log('VUE_APP_BUILD_TYPE', process.env.VUE_APP_BUILD_TYPE);

      console.log('this.auth0Client', this.auth0Client);

      // Handle Browser
      this.isDesktop = process.env.VUE_APP_BUILD_TYPE !== 'app';

      handleVueCallback(window.location.href);

      // Handle Native app
      CapApp.addListener('appUrlOpen', async ({ url }) => {
        console.log('appUrlOpen', url, this.auth0Client);
        handleVueCallback(url);
      });
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          this.error = e;
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.company = await this.auth0Client.getIdTokenClaims();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs thuser out and removes their session on the authorization server */
      async logout(o) {
        await Browser.open({
          url: this.auth0Client.buildLogoutUrl({ returnTo: process.env.VUE_APP_AUTH0_REDIRECT_URI })
        });

        // Ask the SDK to log out locally, but not do the redirect
        this.auth0Client.logout(o)({ localOnly: true });
      },
      buildLogoutUrl(o) {
        return this.auth0Client.buildLogoutUrl(o);
      },
      buildAuthorizeUrl(o) {
        return this.auth0Client.buildAuthorizeUrl(o);
      }
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
