import { App as CapCapp } from '@capacitor/app';
import Vue from 'vue';
import VueResource from 'vue-resource';
import router from './router';
import App from './App.vue';

import { Auth0Plugin } from './auth';

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  redirectUri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
  cacheLocation: 'localstorage',
  onRedirectCallback: (appState, isDesktop) => {
    if (isDesktop) {
      router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
    } else {
      router.push('/');
    }
  }
});

Vue.use(VueResource);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

CapCapp.addListener('appUrlOpen', function ({ url }) {
  console.log('appUrlOpen_main', url);
  if (!url.includes('localhost')) return false;

  const slug = url.split('.app').pop();

  if (slug) {
    router.push({
      path: slug
    });
  }
});
