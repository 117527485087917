import Vue from 'vue';
import VueRouter from 'vue-router';

import Readings from '../Readings';

import { authGuard } from '@/auth/authGuard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    beforeEnter: authGuard,
    component: Readings
    // children: [
    //   {
    //     path: '',
    //     name: 'Home',
    //     component: () => import('../views/Home'),
    //     meta: {
    //       title: 'Home'
    //     }
    //   },
    //   {
    //     path: '/onboarding',
    //     name: 'onboarding',
    //     component: () => import('../views/Onboarding')
    //   }
    // ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
