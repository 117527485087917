<template>
  <div v-if="meters.length > 0 && !loading">
    <select class="form-control" type="text" @input="onSelect">
      <option selected>Select a meter</option>
      <optgroup v-for="util in types.filter(t => meters.some(a => a.type === t.toLowerCase()))" :key="util" :label="util">
        <option v-for="meter in meters.filter(a => a.type === util.toLowerCase())" :key="meter._id" :value="meter._id">
          {{ meter.meterPointNumber ? `[${meter.meterPointNumber}] ` : '' }}{{ meter.meterSerialNumber ? `[${meter.meterSerialNumber}] ` : ''
          }}{{ meter.parentAccountId ? '(Sub)' : '' }} {{ meter.name }}
        </option>
      </optgroup>
    </select>
  </div>
  <div v-else-if="meters.length === 0 && !loading">
    <div class="alert alert-warning">No meters found</div>
  </div>
  <div v-else-if="loading" class="spinner-wrapper">
    <Spinner />
  </div>
</template>
<script>
import Spinner from '@/components/Spinner';

export default {
  name: 'MeterSelector',
  components: {
    Spinner
  },
  props: {
    meters: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      types: ['Electricity', 'Gas', 'Water']
    };
  },
  methods: {
    onSelect(e) {
      this.$emit('select', e.target.value);
    }
  }
};
</script>
