import { Browser } from '@capacitor/browser';

import { getInstance } from './index';
import httpInterceptor from './httpInterceptor';

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    console.log('isDesktop', authService.isDesktop);
    console.log('isAuthenticated', authService.isAuthenticated);

    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      const token = await authService.getTokenSilently({
        redirect_uri: process.env.VUE_APP_AUTH0_REDIRECT_URI
      });

      httpInterceptor(token, authService);

      return next();
    }

    console.log(to);

    if (authService.isDesktop) {
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    } else {
      const url = await authService.buildAuthorizeUrl();
      console.log(url);
      Browser.open({ url });
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }
  });
};
