<template>
  <div>
    <div class="content">
      <div v-if="!loading && status === 'success'">
        <div class="card mb-3">
          <label class="mb-3">Reading submitted successfully!</label>

          <table>
            <tbody>
              <tr>
                <th>Submitted At</th>
                <td>{{ createdReading.submittedAt }}</td>
              </tr>
              <tr>
                <th>Value</th>
                <td>{{ createdReading.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="photoUri">
          <div class="photo">
            <img :src="photoUri" alt="photo" />
          </div>
        </div>
      </div>
      <div v-else-if="!loading && status === 'error'">
        There was a problem submitting this reading. Please try again. If the problem persists, please contact Etainabl support.
      </div>
      <div v-else-if="loading" class="spinner-wrapper">
        <Spinner />
      </div>
    </div>
    <div class="footer-box">
      <button class="btn btn-block" @click="onClickReset">Submit Another</button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';

import Spinner from '@/components/Spinner';

export default {
  name: 'Confirmation',
  components: {
    Spinner
  },
  props: {
    meter: {
      type: Object,
      required: true
    },
    reading: {
      type: String,
      required: true
    },
    s3Key: {
      type: String,
      required: false,
      default: null
    },
    photoUri: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      status: 'pending',
      createdReading: {}
    };
  },
  mounted() {
    this.createReading();
  },
  methods: {
    async createReading() {
      this.loading = true;

      const { data } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/readings`, {
        value: this.reading.reading,
        isReset: this.reading.isReset,
        type: 'A',
        source: 'app',
        accountId: this.meter._id,
        companyId: this.meter.companyId,
        entityId: this.meter.entityId,
        submittedAt: new Date(),
        s3Key: this.s3Key
      });

      if (data._id) {
        this.createdReading = data;
        this.status = 'success';
      } else {
        this.status = 'error';
      }

      this.loading = false;
    },
    onClickReset() {
      this.$emit('reset');
    }
  }
};
</script>
