<template>
  <div v-if="assets.length > 0 && !loading">
    <select class="form-control" type="text" @input="onSelect">
      <option selected>Select an asset</option>
      <option v-for="asset in assets" :key="asset._id" :value="asset._id">
        {{ asset.siteName }}{{ typeof asset.distance !== 'undefined' ? ` [${asset.distance.toFixed(1)} km]` : '' }}
      </option>
    </select>
  </div>
  <div v-else-if="assets.length === 0 && !loading">
    <div class="alert alert-warning">No assets found</div>
  </div>
  <div v-else-if="loading" class="spinner-wrapper">
    <Spinner size="" />
  </div>
</template>
<script>
import Spinner from '@/components/Spinner';

export default {
  name: 'AccountSelector',
  components: {
    Spinner
  },
  props: {
    assets: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSelect(e) {
      this.$emit('select', e.target.value);
    }
  }
};
</script>
