<template>
  <div>
    <div class="content">
      <div class="card">
        <p>Optionally take a photo of the meter with the reading shown clearly (where possible).</p>
        <button class="btn btn-block" @click="takePhoto">Take Photo</button>
      </div>

      <div v-if="photo && !uploading" class="form-box">
        <div class="photo">
          <img :src="photo.dataUrl" alt="photo" />
        </div>
      </div>

      <div v-else-if="photo && uploading" class="form-box">
        <Spinner>Uploading... {{ uploadProgress }}%</Spinner>
      </div>

      <div v-else-if="photo && uploaded" class="form-box">Upload Complete!</div>
    </div>
    <div class="footer-box">
      <div class="row">
        <div class="col-3">
          <button class="btn btn-block btn-grey" @click="onClickBack">Back</button>
        </div>
        <div class="col-9">
          <button class="btn btn-block" @click="onClickNext">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Camera, CameraResultType } from '@capacitor/camera';
import Vue from 'vue';

import Spinner from '@/components/Spinner';

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export default {
  name: 'Photo',
  components: {
    Spinner
  },
  data() {
    return {
      photo: null,
      uploadUrl: null,
      uploadProgress: 0,
      uploading: false,
      uploaded: false,
      s3Key: null
    };
  },
  methods: {
    async takePhoto() {
      this.photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.DataUrl
      });

      const uploadUrlResponse = await Vue.http.get(`${process.env.VUE_APP_API_URL}/readings/photo-upload-url?contentType=image/${this.photo.format}`);
      this.uploadUrl = uploadUrlResponse.data.uploadUrl;
      this.s3Key = uploadUrlResponse.data.s3Key;
    },
    async uploadPhoto() {
      this.uploading = true;

      const photoBinary = dataURLtoFile(this.photo.dataUrl, 'photo');

      const uploadResponse = await Vue.http.put(this.uploadUrl, photoBinary, {
        headers: {
          'Content-Type': `image/${this.photo.format}`
        },
        progress: e => {
          this.uploadProgress = Math.round((e.loaded * 100) / e.total);
        }
      });

      if (uploadResponse.ok) {
        this.uploaded = true;
        this.uploading = false;
      }
    },
    async onClickNext() {
      if (this.photo) {
        await this.uploadPhoto();
      }

      this.$emit('submit', this.s3Key, this.photo ? this.photo.dataUrl : null);
    },
    onClickBack() {
      this.$emit('back');
    }
  }
};
</script>
<style>
.photo {
  width: 100%;
  height: auto;
}
.photo img {
  width: 100%;
  max-height: 350px;
  object-fit: contain;
}
</style>
